//@ts-ignore
import { BehaviorSubject } from 'rxjs'
import { SessionType } from '../../Type/SessionType'
import { LocalStorage } from '../../Common/LocalStorage'
import { addSeconds } from 'date-fns'
import { Config } from '../../Config/Config'
import { useAxios } from '../../Hooks/useAxios'
import { Endpoints } from '../../Config/Endpoints'
import qs from 'qs'

export const getToken = () => {
    let token = LocalStorage.getToken()

    if (token) {
        let now = new Date()
        let time = new Date(LocalStorage.getExpirationDate())

        if (now >= time) {
            logout()
            return false
        } else if (now < time) {
            return token
        }
    } else {
        logout()
        return token
    }
}

export const getRefreshToken = () => {
    return LocalStorage.getRefreshToken() || null
}

const getExpireTime = (time: string) => {
    let timeBefore = Config.TIME_BEFORE_REFRESH_TOKEN * 60
    let res = Number(time) - timeBefore
    return (res * 1000).toString()
}

export const setSessionRefreshToken = (data) => {
    LocalStorage.setToken(data.token)
    LocalStorage.setExpirationDate(
        addSeconds(new Date(), Number(data.expires_in)).toString(),
    )
    // let expiretTime =
    //   (Number(data.expires_in) - Config.TIME_REFRESH_NOTIFICATIONS * 60) * 1000;
    LocalStorage.setExpirationTime(getExpireTime(data.expires_in))
    LocalStorage.setRefreshToken(data.refresh_token)
}

export const GET_LOGGED_IN = (username: string, password: string) =>
    new Promise((resolve, reject) => {
        const { externalHttpRequest } = useAxios(false, false, false)

        auth$.next({
            sessionToken: null,
            error: false,
            pending: true,
            user: null,
        })

        const params = qs.stringify({
            client_id: Config.CLIENT_ID,
            grant_type: 'password',
            username,
            password,
        })

        externalHttpRequest(
            'post',
            Config.END_POINT_LOGIN + Endpoints.POST_LOGIN,
            params,
            (response: any) => {
                const sessionToken = response.data.access_token
                const refreshExpiresIn = response.data.refresh_expires_in
                LocalStorage.setToken(sessionToken)
                LocalStorage.setRefreshToken(response.data.refresh_token)
                LocalStorage.setExpirationDate(
                    addSeconds(
                        new Date(),
                        Number(response.data.expires_in),
                    ).toString(),
                )
                // let expiretTime =
                //   (Number(response.data.data.expires_in) -
                //     Config.TIME_REFRESH_NOTIFICATIONS * 60) *
                //   1000;
                LocalStorage.setExpirationTime(
                    getExpireTime(response.data.expires_in),
                )

                resolve({
                    sessionToken,
                    error: false,
                    pending: false,
                    user: null,
                })
            },
            (errors: any) => {
                console.log(errors.response)
                resolve({
                    sessionToken: null,
                    error: errors.response.data.error_description,
                    pending: false,
                    user: null,
                })
            },
            () => {},
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        )
    })

export const auth$: BehaviorSubject<SessionType> = new BehaviorSubject({
    sessionToken: LocalStorage.getToken(),
    error: false,
    pending: false,
    user: null,
})

export function login(username: string, password: string) {
    if (!auth$.value.pending) {
        GET_LOGGED_IN(username, password).then((user: SessionType) => {
            auth$.next(user)
        })
    }
}

export function logout() {
    LocalStorage.removeToken()
    LocalStorage.removeRefreshToken()
    LocalStorage.removeExpirationDate()
    LocalStorage.removeExpirationTime()
    LocalStorage.removeApplication()
    auth$.next({
        sessionToken: null,
        error: false,
        user: null,
    })
}

export const RenderIfToken = ({ children }) => {
    return LocalStorage.getToken() ? <>{children}</> : null
}
