export const LocalStorage = {
    getToken(): string | null {
        return localStorage.getItem('sessionToken')
    },
    setToken(token: string): void | null {
        return localStorage.setItem('sessionToken', token)
    },
    removeToken(): void | null {
        return localStorage.removeItem('sessionToken')
    },

    getRefreshToken(): string | null {
        return localStorage.getItem('refresh_token')
    },
    setRefreshToken(refresh_token: string): void | null {
        return localStorage.setItem('refresh_token', refresh_token)
    },
    removeRefreshToken(): void | null {
        return localStorage.removeItem('refresh_token')
    },

    getUser(): string | null {
        return localStorage.getItem('user')
    },
    setUser(user: string): void | null {
        return localStorage.setItem('user', user)
    },
    removeUser(): void | null {
        return localStorage.removeItem('user')
    },

    getExpirationDate(): string | null {
        return localStorage.getItem('expDate')
    },
    setExpirationDate(expDate: string): void | null {
        return localStorage.setItem('expDate', expDate)
    },
    removeExpirationDate(): void | null {
        return localStorage.removeItem('expDate')
    },

    getExpirationTime(): string | null {
        return localStorage.getItem('expTime')
    },
    setExpirationTime(expTime: string): void | null {
        return localStorage.setItem('expTime', expTime)
    },
    removeExpirationTime(): void | null {
        return localStorage.removeItem('expTime')
    },

    getApplication(): string | null {
        return localStorage.getItem('application')
    },
    setApplication(application: string): void | null {
        return localStorage.setItem('application', application)
    },
    removeApplication(): void | null {
        return localStorage.removeItem('application')
    },

    getAdvertiser(): string | null {
        return localStorage.getItem('advertiser')
    },
    setAdvertiser(advertiser: string): void | null {
        return localStorage.setItem('advertiser', advertiser)
    },
    removeAdvertiser(): void | null {
        return localStorage.removeItem('advertiser')
    },
}
