import axios, { Method } from 'axios'
import {
    auth$,
    getRefreshToken,
    getToken,
    setSessionRefreshToken,
} from '../Components/Auth/Auth'
import { Config } from '../Config/Config'
import qs from 'qs'
import { Endpoints } from '../Config/Endpoints'

export const useAxios = (
    defaultHeaders = true,
    baseURL = true,
    checkStatus = true,
) => {
    const isAuthenticated = !!auth$.getValue().sessionToken
    axios.interceptors.request.use(
        (config) => {
            if (defaultHeaders) {
                config.headers = {
                    ...config.headers,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                }
            }
            config.validateStatus = (status: any) => validateStatus(status)

            if (baseURL && !config.baseURL) {
                config.baseURL = Config.END_POINT
            }

            if (isAuthenticated && defaultHeaders) {
                config.headers.Authorization = 'Bearer ' + getToken()
            }
            if (config.data instanceof FormData) {
                if (!config.data.has('lang')) {
                    // poner lenguaje
                    //   config.data.append("lang", i18n.language);
                    config.data.append('lang', 'es')
                }

                config.headers = {
                    ...config.headers,
                    'Content-Type': 'multipart/form-data',
                }
            } else if (typeof config.data === 'string') {
                if (config.data.indexOf('&lang=') === -1) {
                    //   config.data += `&lang=${i18n.language}`;
                    config.data += `&lang=${'es'}`
                }
            } else if (typeof config.data === 'object') {
                // config.data.lang = i18n.language;
                config.data.lang = 'es'
            } else if (typeof config.params === 'object') {
                // config.params.lang = i18n.language;
                config.params.lang = 'es'
            }

            return config
        },
        (error) => {
            Promise.reject(error)
        },
    )

    // axios.interceptors.response.use(
    //   (response) => response,
    //   (error) => {
    //     const originalRequest = error.config;
    //     if (
    //       error.response.status === Config.HTTP_CODE_REFRESH_TOKEN &&
    //       !originalRequest._retry
    //     ) {
    //       originalRequest._retry = true;

    //       const params = qs.stringify({
    //         client_id: Config.LOGIN_CLIENT_ID,
    //         refresh_token: getRefreshToken(),
    //         grant_type: "refresh_token",
    //       });

    //       const uninterceptedAxiosInstance = axios.create();
    //       return uninterceptedAxiosInstance
    //         .post(Config.END_POINT_LOGIN + Endpoints.POST_REFRESH, params, {
    //           headers: {
    //             "content-type": "application/x-www-form-urlencoded",
    //             "Access-Control-Allow-Origin": Config.BASE_URL,
    //           },
    //         })
    //         .then((res) => {
    //           if (res.status === 200) {
    //             const token = res.data.access_token;
    //             const expires_in = res.data.expires_in;
    //             const refresh_expires_in = res.data.refresh_expires_in;
    //             const refresh_token = res.data.refresh_token;
    //             // 1) put token to LocalStorage
    //             setSessionRefreshToken({
    //               token,
    //               expires_in,
    //               refresh_expires_in,
    //               refresh_token,
    //             });
    //             auth$.next({
    //               sessionToken: token,
    //               error: false,
    //               pending: false,
    //             });

    //             // 2) Change Authorization header
    //             axios.defaults.headers.common[
    //               "Authorization"
    //             ] = `Bearer ${token}`;

    //             // 3) return originalRequest object with Axios.
    //             return axios(originalRequest);
    //           }
    //         });
    //     }

    //     return Promise.reject(error);
    //   }
    // );

    const validateStatus = (status: number) => {
        let success = 200
        let successDelete = 204
        let successCreated = 201

        return (
            status === success ||
            status === successDelete ||
            status === successCreated
        )
    }

    const postRefreshToken = () => {
        const params = qs.stringify({
            client_id: Config.CLIENT_ID,
            refresh_token: getRefreshToken(),
            grant_type: 'refresh_token',
        })

        const uninterceptedAxiosInstance = axios.create()
        return uninterceptedAxiosInstance
            .post(Config.END_POINT_LOGIN + Endpoints.POST_REFRESH, params, {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': Config.BASE_URL,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    const token = res.data.data.access_token
                    const expires_in = res.data.data.expires_in
                    const refresh_expires_in = res.data.data.refresh_expires_in
                    const refresh_token = res.data.data.refresh_token
                    // 1) put token to LocalStorage
                    setSessionRefreshToken({
                        token,
                        expires_in,
                        refresh_expires_in,
                        refresh_token,
                    })
                }
            })
    }

    const getHttpRequestSWR = (
        pathRelative: string,
        data: any,
        config: any = {},
    ) =>
        axios
            .get(pathRelative, {
                params: data,
                ...config,
            })
            .then((res) => {
                return res.data
            })

    const getHttpRequest = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
        config: any = {},
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        axios
            .get(pathRelative, {
                params: data,
                cancelToken: source.token,
                ...config,
            })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    const postHttpRequest = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
        config: any = {},
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        axios({
            method: 'post',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            ...config,
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    const deleteHttpRequest = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
        config: any = {},
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        axios({
            method: 'delete',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            ...config,
        })
            .then(function (response) {
                if (success) {
                    success(response)
                }
            })
            .catch(function (errors) {
                if (error) {
                    error(errors)
                }
            })
            .then(function () {
                if (always) {
                    always()
                }
            })
        return source
    }

    const putHttpRequest = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
        config: any = {},
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        axios({
            method: 'put',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            ...config,
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    const patchHttpRequest = (
        pathRelative: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
        config: any = {},
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        axios({
            method: 'patch',
            cancelToken: source.token,
            url: pathRelative,
            data: data,
            ...config,
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    const externalHttpRequest = (
        method: Method,
        url: string,
        data: any,
        success?: any,
        error?: any,
        always?: any,
        config: any = {},
    ) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        const uninterceptedAxiosInstance = axios.create()

        uninterceptedAxiosInstance({
            method,
            cancelToken: source.token,
            url,
            data,
            ...config,
        })
            .then((response) => {
                if (success) {
                    success(response)
                }
            })
            .catch((errors) => {
                if (errors.response && error) {
                    error(errors)
                }
            })
            .then(() => {
                if (always) {
                    always()
                }
            })
        return source
    }

    return {
        getHttpRequest,
        getHttpRequestSWR,
        postHttpRequest,
        putHttpRequest,
        patchHttpRequest,
        deleteHttpRequest,
        externalHttpRequest,
        postRefreshToken,
    }
}
