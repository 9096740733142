// @ts-ignore
import { notification } from 'antd'
import {
    CheckCircleFilled,
    CloseCircleFilled,
    ExclamationCircleFilled,
} from '@ant-design/icons'
import { Config } from '../Config/Config'
import { Constants } from '../Config/Constants'

export const Functions = {
    errorArray: (errors: any) => {
        let description = 'Ha ocurrido un error'

        if (
            errors &&
            errors?.response &&
            errors?.response?.data &&
            errors?.response?.data.errors
        ) {
            description = errors?.response?.data.errors[0].title
        }

        return description
    },
    errorObject: (errors: any) => {
        let description = 'Ha ocurrido un error'

        if (
            errors.response &&
            errors.response.data &&
            errors.response.data.message
        ) {
            description = errors.response.data.message
        } else if (errors.response && errors.response.message) {
            description = errors.response.message
        } else if (errors.message) {
            description = errors.message
        }

        return description
    },

    scrollToTargetAdjusted(id: string) {
        var element = document.getElementById(id)
        var headerOffset = 319
        var elementPosition = element.getBoundingClientRect().top
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        })
    },

    initialLettersToUpper(string: string) {
        let letters = ''
        let count = 0
        if (string) {
            const words = string.split(' ')
            if (words.length > 1) {
                words.forEach((value: string) => {
                    const letter = value.charAt(0)

                    if (letter.length && letter !== '' && count <= 1) {
                        letters += value.charAt(0)
                        count++
                    }
                })
            } else if (words.length === 1) {
                const word = words[0]
                letters =
                    word.charAt(0).toUpperCase() + word.charAt(1).toUpperCase()
            }
        }

        return letters.toUpperCase()
    },

    formatNumber(num: any) {
        if (num && num > 0) {
            num += ''
            const splitStr = num.split('.')
            let splitLeft = splitStr[0]
            const splitRight =
                splitStr.length > 1 ? Constants.SEP_DECIMAL + splitStr[1] : ''
            const regx = /(\d+)(\d{3})/

            while (regx.test(splitLeft)) {
                splitLeft = splitLeft.replace(
                    regx,
                    '$1' + Constants.SEPARATOR + '$2',
                )
            }

            return splitLeft + splitRight
        }

        return 0
    },

    openNotificationError: (description: string, title: string) => {
        notification.error({
            message: title,
            description: description,
            placement: 'bottomLeft',
        })
    },

    openNotificationSuccess: (description: string, title?: string) => {
        notification.success({
            message: title ? title : null,
            description: description,
            placement: 'bottomLeft',
        })
    },

    openNotificationInfo: (description: string, title: string) => {
        notification.info({
            message: title,
            description: description,
            placement: 'bottomLeft',
        })
    },

    openAlertError: (description: string, title?: string) => {
        notification.error({
            message: title ? title : null,
            description: description,
            className: 'notification-alert alert-error',
            placement: 'bottomLeft',
            duration: 1,
            icon: <CloseCircleFilled style={{ color: '#FF7875' }} />,
        })
    },

    openAlertSuccess: (description: string, title?: string) => {
        notification.success({
            message: title ? title : null,
            description: description,
            className: 'notification-alert alert-success',
            placement: 'bottomLeft',
            duration: 1,
            icon: <CheckCircleFilled style={{ color: '#B7EB8F' }} />,
        })
    },

    openAlertWarning: (description: string, title?: string) => {
        notification.warning({
            message: title ? title : null,
            description: description,
            className: 'notification-alert alert-warning',
            placement: 'bottomLeft',
            duration: 1,
            icon: <ExclamationCircleFilled style={{ color: '#FFE58F' }} />,
        })
    },

    handleTableChange: (
        pagination: any,
        filters: any,
        sorter: any,
        setPagination: null | ((value: any) => void),
        setOrder: ((value: any) => void) | null,
    ) => {
        setPagination && setPagination(pagination)

        if (sorter && setOrder) {
            switch (sorter.order) {
                case 'ascend':
                    setOrder({ orderBy: sorter.field, orderType: 'ASC' })
                    break
                case 'descend':
                    setOrder({ orderBy: sorter.field, orderType: 'DESC' })
                    break
                default:
                    setOrder({ orderBy: '', orderType: '' })
                    break
            }
        }
    },

    getBase64: (img: any, callback: any) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
    },

    capitalizeFirstLetter: (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    },

    getTableHeightByWindowsSize: (marginTop: number) => {
        let media768 = window.matchMedia('(max-width: 768px)').matches
        let media992 = window.matchMedia('(max-width: 992px)').matches

        let height = 500
        const vh = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0,
        )

        if (!media768 && !media992) {
            height = vh - marginTop
        }

        return height
    },

    // function to convert number in K, M, B
    formatNumberWithKMB: (number: number) => {
        if (number >= 1000 && number < 1000000) {
            return (number / 1000).toFixed(1) + 'K'
        } else if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M'
        } else {
            return number.toString()
        }
    },
    getTranslationMap: (lang): any => {
        let style
        switch (lang) {
            case 'es':
                style = Config.MAPBOX_STYLE_ES
                break
            case 'pt':
                style = Config.MAPBOX_STYLE_PT
                break
            default:
                style = Config.MAPBOX_STYLE_EN
                break
        }
        return style
    },
    compareTwoObjects(a: any, b: any): boolean {
        let aProps = Object.getOwnPropertyNames(a)
        let bProps = Object.getOwnPropertyNames(b)
        let index = 0
        let is_equal = true

        if (aProps.length != bProps.length) {
            is_equal = false
        }

        while (index < aProps.length && is_equal) {
            let propName = aProps[index]

            if (!b.hasOwnProperty(propName)) {
                is_equal = false
            }
            if (a[propName] !== b[propName]) {
                is_equal = false
            }

            index++
        }

        return is_equal
    },
}
