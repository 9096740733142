import { Suspense, useState } from 'react'

// @ts-ignore
import { Loading } from '@tdt-global/styleguide'
// @ts-ignore
import { Modal } from 'antd'
import { ShowModalType } from '../../Type/ShowModalType'

export const ShowModal = (props: ShowModalType) => {
    const {
        show,
        title,
        component,
        className = '',
        modalProps,
        hideModal,
        confirmLoading,
    } = props

    return (
        <Modal
            title={title}
            onCancel={() => hideModal()}
            visible={show}
            maskClosable={true}
            className={className}
            bodyStyle={{ height: 'auto' }}
            confirmLoading={confirmLoading ? confirmLoading : false}
            {...modalProps}>
            <Suspense fallback={<Loading />}>{component}</Suspense>
        </Modal>
    )
}
