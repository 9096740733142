import useSWR from 'swr'
import { UserModel } from '../Models/UserModel'
import { Endpoints } from '../Config/Endpoints'
import { useAxios } from './useAxios'

export function useUser() {
    const { getHttpRequestSWR } = useAxios()
    const loadUser = (url) => getHttpRequestSWR(url, {})
    const { data, error } = useSWR(Endpoints.GET_USER, loadUser)

    return {
        user: data && new UserModel(data?.data),
        isLoading: !error && !data,
        isError: error,
    }
}
