import { useState } from 'react'
import default_img from '../../Assets/Img/img-default.svg'
import '../../Assets/Sass/Index.css'

type PropsType = {
    url?: string | null
    id?: string
    alt?: string
    style?: any
    isAnimated?: boolean
}

export const ShowImage = ({ ...props }: PropsType) => {
    const [imageLoaded, setImageLoaded] = useState(false)
    const { url, id = '', alt = '', style, isAnimated = false } = props
    let dir = default_img

    const imageIsAnimated = `show-img smooth-image image-${
        imageLoaded ? 'visible' : 'hidden'
    }`

    const imageNotAnimated = 'show-img'

    return (
        <img
            className={isAnimated ? imageIsAnimated : imageNotAnimated}
            id={id}
            alt={alt}
            src={url || dir}
            onError={(ev) => {
                ev.currentTarget.src = default_img
            }}
            style={style}
            typeof='image/avif'
            loading='lazy'
            decoding='async'
            onLoad={() => setImageLoaded(true)}
        />
    )
}
