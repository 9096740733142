import { UserType } from "../Type/UserType";

export class UserModel {
  private _id: number;
  private _email: string;
  private _email_verified: boolean;
  private _first_name: string;
  private _last_name: string;
  private _locale: string[];
  private _organization_id: string[];
  private _username: string;

  constructor(application: UserType) {
    this._id = application.id;
    this._email = application.attributes.email;
    this._email_verified = application.attributes.email_verified;
    this._first_name = application.attributes.first_name;
    this._last_name = application.attributes.last_name;
    this._locale = application.attributes.locale;
    this._organization_id = application.attributes.organization_id;
    this._username = application.attributes.username;
  }

  get id(): number {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value) {
    this._email = value;
  }

  get email_verified(): boolean {
    return this._email_verified;
  }

  set email_verified(value) {
    this._email_verified = value;
  }

  get first_name(): string {
    return this._first_name;
  }

  set first_name(value) {
    this._first_name = value;
  }

  get last_name(): string {
    return this._last_name;
  }

  set last_name(value) {
    this._last_name = value;
  }

  get locale(): string[] {
    return this._locale;
  }

  set locale(value) {
    this._locale = value;
  }
  get organization_id(): string[] {
    return this._organization_id;
  }

  set organization_id(value) {
    this._organization_id = value;
  }
  get username(): string {
    return this._username;
  }

  set username(value) {
    this._username = value;
  }
}
