import Paths from "./Paths";

export const Config = {
  END_POINT: "https://api.tolber.io/dev/",
  END_POINT_LOGIN: "https://api.tolber.io/dev/",
  // END_POINT_PLANNING: 'https://api.planning.dev.tolber.io/',
  END_POINT_PLANNING: "https://api-planning-demo.tolber.io/",
  // END_POINT_LOGIN:
  //   "https://oauth.dev.tolber.io/auth/realms/TDT/protocol/openid-connect/token",
  END_POINT_USER_INFO:
    "https://oauth.dev.tolber.io/auth/realms/TDT/protocol/openid-connect/userinfo",
  LOGIN_CLIENT_ID: "tdt_global",
  CLIENT_ID: "planning",
  TDT_COMMONS: "http://100.25.133.67/api/",
  BASE_URL: "https://post.dev.tolber.io",
  PATH_DEFAULT: Paths.SUITE_APPS,
  PER_PAGE: 15,
  MAP_BOX_STYLE: "",
  MAP_BOX_KEY:
    "pk.eyJ1IjoibWZlcm5hbmRlemJlcnRvbGEiLCJhIjoiY2tvajY5eDRnMHViZDJ2azJkNmo2N3VqNCJ9.JNvVN5GZjZecYoj09NPK6A",
  MAP_BOX_ZOOM: 14,
  MAP_BOX_PITCH: 45,
  TIME_REFRESH_NOTIFICATIONS: 30 * 1000, // segundos * milisegundos
  HTTP_CODE_REFRESH_TOKEN: 407,
  TIME_BEFORE_REFRESH_TOKEN: 5,
  MAPBOX_STYLE_EN:
    "mapbox://styles/mfernandezbertola/ckrgvcc5140ml17okp4iwfkpd",
  MAPBOX_STYLE_ES:
    "mapbox://styles/mfernandezbertola/cksot700c4zrr18louisxz8wg",
  MAPBOX_STYLE_PT:
    "mapbox://styles/mfernandezbertola/cksot7y514zsk18lotghafqdt",
};
