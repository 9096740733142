import { auth$, login, logout, RenderIfToken } from './Components/Auth/Auth'
import { useAxios } from './Hooks/useAxios'
import { LocalStorage } from './Common/LocalStorage'
import { Functions } from './Common/Functions'
import { useUser } from './Hooks/useUser'
import { ShowImage } from './Components/ShowImage/ShowImage'
import { ShowModal } from './Components/ShowModal/ShowModal'
import { Config } from './Config/Config'

export const utility = {
    auth$,
    Config,
    login,
    logout,
    RenderIfToken,
    useAxios,
    LocalStorage,
    Functions,
    useUser,
    ShowImage,
    ShowModal,
}
